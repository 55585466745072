import React, { FunctionComponent } from "react";
import Layout from "../../components/layout/layout"
// import SEO from "../../components/seo"
import Img from "gatsby-image"
import { graphql, Link } from "gatsby"
import {
    mapEdgesToNodes,
    filterOutDocsWithoutSlugs,
    filterOutDocsPublishedInTheFuture,
  } from "../../lib/helpers";
import style from "./nieuws.module.css"

interface IArticleListingProps {
    data: GatsbyTypes.ArticleListingQuery;
}

const ArticleListing: FunctionComponent<IArticleListingProps> = ({data}) => {
    // const title = (articles.openGraph && articles.openGraph.title) || articles.title
    // const description = (articles.openGraph && articles.openGraph.description) || ""
    // const openGraphImage = (articles.openGraph && articles.openGraph.image && articles.openGraph.image.asset && articles.openGraph.image.asset.fixed.src)

    const articles = (data || {}).articles
    ? mapEdgesToNodes(data.articles)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];
    return(
        <Layout>
            {/* <SEO title={title} image={openGraphImage && openGraphImage} description={description} slug={articles.slug.current} /> */}
            <div className={style.main}>
              <h1 className={style.pageTitle}>Nieuws</h1>
              <div className={style.listing}>
                {articles && articles.map(article => (
                  <article key={article.id} className={style.article}>
                    <Link to={`/nieuws/${article.slug.current}/`} className={style.link}>
                      <Img fluid={{ ...article.mainImage.asset.fluid, aspectRatio: 3/2}} className={style.image} />
                      <h1 className={style.title}>{article.title}</h1>
                      <div className={style.lead}>{article.lead}</div>
                      <div className={style.date}>{article.publishedAt}</div>
                    </Link>
                  </article>
                ))}
              </div>
            </div>
        </Layout>
    );
}

export default ArticleListing


export const query = graphql`
  query ArticleListing {
    articles: allSanityArticle(
        limit: 6,
        sort: {fields: [publishedAt], order: DESC},
        filter: {slug: {current: {ne: null}}, publishedAt: {ne: null}}
      ) {
      edges {
        node {
          id
          publishedAt(formatString: "D MMMM YYYY", locale: "nl")
          mainImage {
            asset {
              fluid {
                aspectRatio
                sizes
                src
                srcSet
                srcWebp
                srcSetWebp
              }
            }
          }
          title
          lead
          slug {
            current
          }
        }
      }
    }
  }
`;